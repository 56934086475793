import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { getHomeUri } from "../../store/slices/funzionalitaSlice";
import { cookieHome, notFoundPath, portaleFEUrl } from "../../utils/utilconst";
import { getCookie } from "../../utils/utilfunctions";

const PageLogin = () => {
    const token = useAppSelector(state => state.authInfo.token);
    const organization = useAppSelector(state => state.authInfo.organization);
    const profil = useAppSelector(state => state.authInfo.idProfil);
    const home = useAppSelector(getHomeUri);
    const history = useHistory();

    const homeCookie = getCookie(cookieHome);

    useEffect(() => {
        if ((!token || !organization || !profil) && homeCookie) {
            window.location.href = portaleFEUrl;
        } else {
            history.push(home ?? notFoundPath);
        }
    }, [organization, profil, token, home, history, homeCookie]);

    return <></>
}
export default PageLogin;