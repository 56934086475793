import React from 'react'
import { Box } from '@material-ui/core'
import HelpButton from '../../buttons/HelpButton'
import LanguageSelector from '../LanguageSelector'
import ButtonUser from '../ButtonUser';

const ButtonLogout = React.lazy(() => import('../ButtonLogout'));

interface RightActionButtonsProps {
  guidaHelp: boolean;
  titleID: number;
  titleBar: string;
}

const RightActionButtonsDesktop = (props: RightActionButtonsProps) => {
  const { guidaHelp, titleID, titleBar } = props;

  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-end'>
      <LanguageSelector />
      {guidaHelp && <HelpButton topics={{ [titleID]: titleBar }} size='large' />}
      <ButtonLogout />
      <ButtonUser />
    </Box>
  )
}

export default RightActionButtonsDesktop