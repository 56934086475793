import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { appBarHeight, sideBarWidth } from "../../../utils/styleconst";
const drawerWidth = sideBarWidth;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        toolBar: {
            minHeight: appBarHeight,
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            
        },
        appBarShift: {
            // width: `calc(100% - ${drawerWidth}px)`,
             marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
            
        },
        hide: {
             display: 'none',               
           
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
          
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,     
               
        },
        drawerDesktop : {
            [theme.breakpoints.down('sm')] : {display:'none'},
            [theme.breakpoints.up('md')] : {display:'block'},
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },    
        },
        drawerMobile : {
            [theme.breakpoints.down('sm')] : {display:'block'},
            [theme.breakpoints.up('md')] : {display:'none'},
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            

        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.background.paper,
        },
      
        contentHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
            backgroundColor: 'transparent',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        title: {
            flexGrow: 1,
        },
        logo: {
            maxHeight: 45,
        },
        pointer: {
            cursor: "pointer"
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        marginright: {
            marginRight: theme.spacing(2)
        }
    }),
);
export default useStyles;