export const find = "/cerca";
export const insert = "/inserisci";
export const update = "/modifica";
export const refresh = "/refresh";
export const remove = "/elimina";
export const restore = "/restore";
export const definitiveDelete = "/delete";

export const allValid = "/all/valid";
export const allDeleted = "/all/deleted";
export const allData = "/all/data";
export const allComplete = "/all/completa";
export const noAbilitazione = "/noabilitazione";